import Vue from 'vue'
import VueI18n from 'vue-i18n'
import constants from '@/constants'
import config from '@/config'

import enVuetify from 'vuetify/lib/locale/en'
import viVuetify from 'vuetify/lib/locale/vi'

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('./en/global.json'),
    $vuetify: enVuetify
  },
  vi: {
    ...require('./vi/global.json'),
    $vuetify: viVuetify
  }
}

const localLang = localStorage.getItem(constants.LANGUAGE) || config.app.defaultLanguage
localStorage.setItem(constants.LANGUAGE, localLang)
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || localLang,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || localLang,
  messages
})
