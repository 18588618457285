export default {
  namespaced: true,
  state: {
    barColor: 'rgba(255,255,255, 0.3), rgba(255,255,255, 0.3)',
    drawer: null,
    breadcrumbs: [],
    fullname: '',
    orderCreateActive: false,
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    SET_BREADCRUMBS(state, payload) {
      state.breadcrumbs = payload
    },
    SET_FULL_NAME(state, val) {
      state.fullname = val
    },
    SET_ORDER_CREATE(state, payload) {
      state.orderCreateActive = payload
    },
  },
  actions: {
    setDrawer({ commit }, value) {
      commit('SET_DRAWER', value)
    },
    setBreadcrumbs({ commit }, value) {
      commit('SET_BREADCRUMBS', value)
    },
    setFullName({ commit }, value) {
      commit('SET_FULL_NAME', value)
    },
    setOrderCreate({ commit }, value) {
      commit('SET_ORDER_CREATE', value)
    },
  }
}
