import axios from 'axios'
import constants from '@/constants'
import config from '@/config'
import { ResponseWrapper } from './util'
import apis from './apis'

export class BaseService {
  static requestAuth() {
    const language = localStorage.getItem(constants.LANGUAGE)
    axios.defaults.baseURL = config.app.baseApiUrl
    axios.defaults.headers = {
      'Accept-Language': language,
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*'
    }
    return axios
  }

  static request(status = { auth: false }) {
    const language = localStorage.getItem(constants.LANGUAGE)
    axios.defaults.baseURL = config.app.baseApiUrl
    axios.defaults.headers = {
      'Accept-Language': language,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }

    if (status.auth) {
      const token = localStorage.getItem(constants.TOKEN)
      axios.defaults.headers['Authorization'] = `Bearer ${token}`
    }

    return axios
  }

  static requestForm(status = { auth: false }) {
    const language = localStorage.getItem(constants.LANGUAGE)
    axios.defaults.baseURL = config.app.baseApiUrl
    axios.defaults.headers = {
      'Accept-Language': language,
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    }

    if (status.auth) {
      const token = localStorage.getItem(constants.TOKEN)
      axios.defaults.headers['Authorization'] = `Bearer ${token}`
    }

    return axios
  }

  static requestFile(status = { auth: false }) {
    const language = localStorage.getItem(constants.LANGUAGE)
    axios.defaults.headers = {
      'Accept-Language': language,
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*'
    }

    if (status.auth) {
      const token = localStorage.getItem(constants.TOKEN)
      axios.defaults.headers['Authorization'] = `Bearer ${token}`
    }

    return axios
  }

  static handleResponse(response) {
    return new ResponseWrapper(response.data)
  }

  static async getProvinceAll() {
    try {
      const response = await this.request().get(apis.province_all)
      if (response != null) {
        return this.handleResponse(response)
      }
      return response
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getAllDistrict() {
    try {
      const response = await this.request().get(apis.get_all_district)
      if (response != null) {
        return this.handleResponse(response)
      }
      return response
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getAllCommune() {
    try {
      const response = await this.request().get(apis.get_all_commune)
      if (response != null) {
        return this.handleResponse(response)
      }
      return response
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async findDistrictByProvinceCode(pars) {
    try {
      const response = await this.request({
        auth: true
      }).get(apis.find_all_districts, {
        params: pars
      })
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async findCommuneByDistrictCode(pars) {
    try {
      const response = await this.request({
        auth: true
      }).get(apis.find_all_communes, {
        params: pars
      })
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getLogisticPartner() {
    try {
      const response = await this.request({ auth: true }).get(
        apis.get_logistic_partner
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }
}
