import { BaseService } from './baseService'
import { StorageService } from './storageService'
import apis from './apis'
import constants from '@/constants'

export class AuthService extends BaseService {
  /**
   * Handle request login
   */
  static async login(pars) {
    try {
      const response = await this.request().post(apis.login, pars)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async revoke() {
    try {
      const response = await this.request({ auth: true }).get(apis.revoke)
      if (
        response.data.status === constants.statusCode.ok &&
        response.data.payload
      ) {
        this.logout()
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  static logout() {
    StorageService.clearLocalStorage()
  }
}
