export default {
  // no auth
  LOGIN: 'LOGIN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_UPDATE: 'FORGOT_PASSWORD_UPDATE',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  ACCOUNT: 'ACCOUNT',

  OTP: 'OTP',
  // auth
  DASHBOARD: 'DASHBOARD',

  // table price
  PARTNER_LIST: 'PARTNER_LIST',
  PARTNER_PRICES: 'PARTNER_PRICES',
  PARTNER_ACCOUNTS: 'PARTNER_ACCOUNTS',

  SHOP_PRICE_LIST: 'SHOP_PRICE_LIST',
  SHOP_CREATE_OR_UPDATE_PRICES: 'SHOP_CREATE_OR_UPDATE_PRICES',

  GUEST_PRICES: 'GUEST_PRICES',

  CUSTOMERS: 'CUSTOMERS',
  CUSTOMERS_CREATE: 'CUSTOMERS_CREATE',
  CUSTOMERS_ACTIVATION_WAITING: 'CUSTOMERS_ACTIVATION_WAITING',
  CUSTOMER_ACTIVATION_WAITING_DETAIL: 'CUSTOMER_ACTIVATION_WAITING_DETAIL',
  CUSTOMER_DETAIL: 'CUSTOMER_DETAIL',

  EMPLOYEES: 'EMPLOYEES',
  EMPLOYEE_CREATE: 'EMPLOYEE_CREATE',
  EMPLOYEE_DETAIL: 'EMPLOYEE_DETAIL',

  ORDERS: 'ORDERS',
  LIST_ORDER_PROCESS: 'LIST_ORDER_PROCESS',
  LIST_ORDER_ONE_PART: 'LIST_ORDER_ONE_PART',
  ORDER_CREATE: 'ORDER_CREATE',
  ORDER_CREATE_MULTIPLE: 'ORDER_CREATE_MULTIPLE',
  ORDER_CREATE_MULTI_BOX: 'ORDER_CREATE_MULTI_BOX',
  ORDER_DETAIL: 'ORDER_DETAIL',
  ORDER_UPDATE: 'ORDER_UPDATE',
  ORDER_MULTIBOX_UPDATE: 'ORDER_MULTIBOX_UPDATE',
  ACCOUNT_LIST: 'ACCOUNT_LIST',
  PARTNER_PRICES_LIST: 'PARTNER_PRICES_LIST',
  CREATE_PARTNER_PRICE: 'CREATE_PARTNER_PRICE',
  DETAIL_PARTNER_PRICE: 'DETAIL_PARTNER_PRICE',

  POST_OFFICE_LIST: 'POST_OFFICE_LIST',
  CREATE_POST_OFFICE: 'CREATE_POST_OFFICE',
  DETAIL_POST_OFFICE: 'DETAIL_POST_OFFICE',

  PRICE_LIST_PO_PUBLIC: "PRICE_LIST_PO_PUBLIC",
  PO_PRICE_CREATE_OR_UPDATE_PRICES: "PO_PRICE_CREATE_OR_UPDATE_PRICES",

  LIST_ADMIN: "LIST_ADMIN",

  LIST_STATIS_ORDER: 'LIST_STATIS_ORDER',
  LIST_STATIS_REVENUE: 'LIST_STATIS_REVENUE'
}
