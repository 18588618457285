import Vue from 'vue'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  localize
} from 'vee-validate'
import {
  email,
  max,
  min,
  numeric,
  required,
  regex,
  confirmed
} from 'vee-validate/dist/rules'
import config from '@/config'
// Import languages
import en from '@/locales/en/validate'
import vi from '@/locales/vi/validate'

// Install required rule.
extend('required', required)

// Install email rule.
extend('email', email)

// Install min rule.
extend('numeric', numeric)

// Install numeric rule.
extend('min', min)

// Install max rule.
extend('max', max)

// Install regex rule.
extend('regex', regex)

// Install confirmed
extend('confirmed', confirmed)

// Defined two word
extend('twoWord', val => {
  const str = val.trim().split(' ')
  const lastStr = str[str.length - 1]
  return str.length >= 2 || (lastStr !== '' && str.length === 2)
})

localize({
  en: en,
  vi: vi
})
let LOCALE = config.app.defaultLanguage

// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, 'locale', {
  get() {
    return LOCALE
  },
  set(val) {
    LOCALE = val
    localize(val)
  }
})
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
