import Vue from 'vue'
import Router from 'vue-router'

import routeNames from './routeNames'
import routePaths from './routePaths'

Vue.use(Router)

export default new Router({
  linkActiveClass: 'is-active',
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/views/layout/default'),
      children: [
        {
          name: routeNames.PARTNER_LIST,
          path: routePaths.PARTNER_LIST,
          component: () => import('@/views/table_price/PartnerList')
        },
        {
          name: routeNames.PARTNER_PRICES,
          path: routePaths.PARTNER_PRICES,
          component: () => import('@/views/table_price/Partner')
        },
        {
          name: routeNames.PARTNER_ACCOUNTS,
          path: routePaths.PARTNER_ACCOUNTS,
          component: () => import('@/views/table_price/PartnerAccounts')
        },
        {
          name: routeNames.SHOP_PRICE_LIST,
          path: routePaths.SHOP_PRICE_LIST,
          component: () => import('@/views/table_price/ShopList')
        },
        {
          name: routeNames.SHOP_CREATE_OR_UPDATE_PRICES,
          path: routePaths.SHOP_CREATE_OR_UPDATE_PRICES,
          component: () => import('@/views/table_price/ShopCreateOrUpdate')
        },
        {
          name: routeNames.PRICE_LIST_PO_PUBLIC,
          path: routePaths.PRICE_LIST_PO_PUBLIC,
          component: () => import('@/views/table_price/pricePublicList')
        },
        {
          name: routeNames.PO_PRICE_CREATE_OR_UPDATE_PRICES,
          path: routePaths.PO_PRICE_CREATE_OR_UPDATE_PRICES,
          component: () => import('@/views/table_price/pricePublicCreateOrUpdate')
        },
        {
          name: routeNames.GUEST_PRICES,
          path: routePaths.GUEST_PRICES,
          component: () => import('@/views/table_price/Guest')
        },
        {
          name: routeNames.CUSTOMERS,
          path: routePaths.CUSTOMERS,
          component: () => import('@/views/customer/List')
        },
        {
          name: routeNames.CUSTOMERS_CREATE,
          path: routePaths.CUSTOMERS_CREATE,
          component: () => import('@/views/customer/create')
        },
        {
          name: routeNames.CUSTOMER_DETAIL,
          path: routePaths.CUSTOMER_DETAIL,
          component: () => import('@/views/customer/Detail')
        },
        {
          name: routeNames.CUSTOMERS_ACTIVATION_WAITING,
          path: routePaths.CUSTOMERS_ACTIVATION_WAITING,
          component: () => import('@/views/customer/ListActivationWaiting')
        },
        {
          name: routeNames.CUSTOMER_ACTIVATION_WAITING_DETAIL,
          path: routePaths.CUSTOMER_ACTIVATION_WAITING_DETAIL,
          component: () => import('@/views/customer/DetailActivationWaiting')
        },
        {
          name: routeNames.EMPLOYEES,
          path: routePaths.EMPLOYEES,
          component: () => import('@/views/employee/List')
        },
        {
          name: routeNames.EMPLOYEE_CREATE,
          path: routePaths.EMPLOYEE_CREATE,
          component: () => import('@/views/employee/Create')
        },
        {
          name: routeNames.EMPLOYEE_DETAIL,
          path: routePaths.EMPLOYEE_DETAIL,
          component: () => import('@/views/employee/Detail')
        },
        {
          name: routeNames.ORDERS,
          path: routePaths.ORDERS,
          component: () => import('@/views/order/List')
        },
        {
          name: routeNames.LIST_ORDER_PROCESS,
          path: routePaths.LIST_ORDER_PROCESS,
          component: () => import('@/views/order/ListOrderProcess')
        },
        {
          name: routeNames.LIST_ORDER_ONE_PART,
          path: routePaths.LIST_ORDER_ONE_PART,
          component: () => import('@/views/order/ListOrderPartial')
        },
        {
          name: routeNames.ORDER_CREATE,
          path: routePaths.ORDER_CREATE,
          component: () => import('@/views/create_order/Create')
        },
        {
          name: routeNames.ORDER_CREATE_MULTIPLE,
          path: routePaths.ORDER_CREATE_MULTIPLE,
          component: () => import('@/views/create_order/CreateMultiple')
        },
        {
          name: routeNames.ORDER_CREATE_MULTI_BOX,
          path: routePaths.ORDER_CREATE_MULTI_BOX,
          component: () => import('@/views/create_order/CreateMultiBox')
        },
        {
          name: routeNames.ORDER_DETAIL,
          path: routePaths.ORDER_DETAIL,
          component: () => import('@/views/order/Detail')
        },
        {
          name: routeNames.ORDER_UPDATE,
          path: routePaths.ORDER_UPDATE,
          component: () => import('@/views/order/Update')
        },
        {
          name: routeNames.ORDER_MULTIBOX_UPDATE,
          path: routePaths.ORDER_MULTIBOX_UPDATE,
          component: () => import('@/views/order/updateOrderMultiBox')
        },
        {
          name: routeNames.ACCOUNT,
          path: routePaths.ACCOUNT,
          component: () => import('@/views/user/Detail')
        },
        {
          name: routeNames.PARTNER_PRICES_LIST,
          path: routePaths.PARTNER_PRICES_LIST,
          component: () => import('@/views/table_price/PartnerPriceList')
        },
        {
          name: routeNames.CREATE_PARTNER_PRICE,
          path: routePaths.CREATE_PARTNER_PRICE,
          component: () => import('@/views/table_price/CreatePartnerPrice')
        },
        {
          name: routeNames.DETAIL_PARTNER_PRICE,
          path: routePaths.DETAIL_PARTNER_PRICE,
          component: () => import('@/views/table_price/DetailPartnerPrice')
        },
        {
          name: routeNames.CREATE_POST_OFFICE,
          path: routePaths.CREATE_POST_OFFICE,
          component: () => import('@/views/post_office/create')
        },
        {
          name: routeNames.POST_OFFICE_LIST,
          path: routePaths.POST_OFFICE_LIST,
          component: () => import('@/views/post_office/list')
        },
        {
          name: routeNames.DETAIL_POST_OFFICE,
          path: routePaths.DETAIL_POST_OFFICE,
          component: () => import('@/views/post_office/detail')
        },
        {
          name: routeNames.LIST_ADMIN,
          path: routePaths.LIST_ADMIN,
          component: () => import('@/views/employee/listAdmin')
        },
        {
          name: routeNames.LIST_STATIS_ORDER,
          path: routePaths.LIST_STATIS_ORDER,
          component: () => import('@/views/statistical/ListStatisOrder')
        },
        {
          name: routeNames.LIST_STATIS_REVENUE,
          path: routePaths.LIST_STATIS_REVENUE,
          component: () => import('@/views/statistical/ListStatisRevenue')
        }
      ]
    },
    {
      name: routeNames.CHANGE_PASSWORD,
      path: routePaths.CHANGE_PASSWORD,
      component: () => import('@/views/user/ChangePassword')
    },
    {
      name: routeNames.UPDATE_PASSWORD,
      path: routePaths.UPDATE_PASSWORD,
      component: () => import('@/views/user/UpdatePassword')
    },
    // Authenticated
    {
      name: routeNames.LOGIN,
      path: routePaths.LOGIN,
      component: () => import('@/views/auth/Login')
    },
    {
      name: routeNames.FORGOT_PASSWORD,
      path: routePaths.FORGOT_PASSWORD,
      component: () => import('@/views/auth/ForgotPassword')
    },
    {
      name: routeNames.FORGOT_PASSWORD_UPDATE,
      path: routePaths.FORGOT_PASSWORD_UPDATE,
      component: () => import('@/views/auth/ForgotPasswordUpdate')
    },
    {
      name: routeNames.OTP,
      path: routePaths.OTP,
      component: () => import('@/views/otp/View')
    }
  ]
})
