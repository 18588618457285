export default {
  // no auth
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_UPDATE: '/forgot-password-update',

  CHANGE_PASSWORD: '/change-password',
  UPDATE_PASSWORD: '/update-password',
  ACCOUNT: '/account',

  OTP: '/otp',

  // auth
  DASHBOARD: '/',

  // table price
  PARTNER_LIST: '/partner-list',
  PARTNER_PRICES: '/partner-prices',
  PARTNER_ACCOUNTS: '/partner-accounts',

  SHOP_PRICE_LIST: '/shop-price-list',
  SHOP_CREATE_OR_UPDATE_PRICES: '/shop-prices',

  GUEST_PRICES: '/guest-prices',

  CUSTOMERS: '/customers',
  CUSTOMERS_CREATE: '/customer-create',
  CUSTOMER_DETAIL: '/customer-detail',
  CUSTOMERS_ACTIVATION_WAITING: '/customers-activation-waiting',
  CUSTOMER_ACTIVATION_WAITING_DETAIL: '/customer-activation-waiting-detail',

  EMPLOYEES: '/employees',
  EMPLOYEE_CREATE: '/employee-create',
  EMPLOYEE_DETAIL: '/employee-detail',

  ORDERS: '/orders',
  LIST_ORDER_PROCESS: '/order-process',
  LIST_ORDER_ONE_PART: '/order_one_part',
  ORDER_CREATE: '/order-create',
  ORDER_CREATE_MULTIPLE: '/order-create-multiple',
  ORDER_CREATE_MULTI_BOX: '/order-create-multi-box',
  ORDER_DETAIL: '/order-detail',
  ORDER_UPDATE: '/order-update',
  ORDER_MULTIBOX_UPDATE: '/order_multibox_update',
  ACCOUNT_LIST: '/list-account',
  PARTNER_PRICES_LIST: '/partner-prices-list',
  CREATE_PARTNER_PRICE: '/create-partner-price',
  DETAIL_PARTNER_PRICE: '/detail-partner-price',

  POST_OFFICE_LIST: '/post-office-list',
  CREATE_POST_OFFICE: '/create-post-office',
  DETAIL_POST_OFFICE: '/detail-post-office',

  PRICE_LIST_PO_PUBLIC: '/price-list-po-admin',
  PO_PRICE_CREATE_OR_UPDATE_PRICES: '/price-po',

  LIST_ADMIN: '/list-admin',

  LIST_STATIS_ORDER: '/list-statis-order',
  LIST_STATIS_REVENUE: '/list-statis-revenue'
}
