import constants from '@/constants'
import routeNames from './routeNames'
import { StorageService } from '@/services/storageService'
import $store from '../store'

const pathNoAuths = [
  routeNames.FORGOT_PASSWORD,
  routeNames.FORGOT_PASSWORD_UPDATE,
  routeNames.UPDATE_PASSWORD,
  routeNames.OTP
]

/**
 * Current user state initialization
 * @WARN Must be always first in middleware chain
 */
export async function initCurrentUserStateMiddleware(to, from, next) {
  const token = localStorage.getItem(constants.TOKEN)
  // If current page != login vs user not logged
  if (to.name !== routeNames.LOGIN && token === null) {
    // Redirect to default login if path dont include pathAllows
    if (!pathNoAuths.includes(to.name)) {
      return next({ name: routeNames.LOGIN })
    }

    return next(pathNoAuths)
  }

  // When logged, auto redirect dashboard
  if (to.name === routeNames.LOGIN && token !== null) {
    return next({ name: routeNames.DASHBOARD })
  }

  // check timeout token
  if (StorageService.checkTokenTimeout()) {
    return next({ name: routeNames.LOGIN })
  }

  next()
}

/**
 * Check access permission to auth routes
 */
export function checkAccessMiddleware(to, from, next) {
  const currentUserId = $store.state.user.currentUser.id
  const isAuthRoute = to.matched.some((item) => item.meta.isAuth)

  if (isAuthRoute && currentUserId) return next()
  if (isAuthRoute) return next({ name: routeNames.LOGIN })
  next()
}

export function setPageTitleMiddleware(to, from, next) {
  const pageTitle = to.matched.find((item) => item.meta.title)

  if (pageTitle) window.document.title = pageTitle.meta.title
  next()
}
