import { StorageService } from '@/services/storageService'

export default {
  SET_CURRENT_USER(state, currentUserData) {
    state.currentUser = currentUserData
    StorageService.setCurrentUser({
      currentUser: currentUserData
    })
  },
  SET_TIME_GET_OTP(state, datetime) {
    state.timeGetOtp = datetime
  },
  SET_FORGOT_PASSWORD(state, data) {
    state.forgotPassword = data
  },
  SET_CHECK_CURRENT_PASSWORD(state, data) {
    state.checkCurrentPassword = data
  }
}
