<template>
  <v-breadcrumbs :class="clazz" :items="items">
    <template v-slot:divider>
      <v-icon size="20"> / </v-icon>
    </template>
    <v-breadcrumbs-item
      slot="item"
      slot-scope="{ item }"
      :active-class="item.isActive ? 'active' : ''"
      :class="item.disabled ? `disabled` : ``"
      :disabled="item.disabled"
      :replace="true"
      :style="item.disabled && !item.tooltip ? 'pointer-events: none' : ''"
      :to="item.href"
      class="text-capitalize"
      large
    >
      <v-tooltip v-if="item.tooltip" bottom>
        <template v-slot:activator="{ on }">
          <h4 v-on="on">{{ item.text }}</h4>
        </template>
        <span class="title-page">{{ item.text }}</span>
      </v-tooltip>
      <h4 v-else class="title-page">{{ $t(item.text) }}</h4>
    </v-breadcrumbs-item>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    items: {
      type: Array,
      default: undefined,
    },
    clazz: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.v-breadcrumbs__item {
  h4 {
    color: black !important;
  }
  &.active {
    h4 {
      color: #0F90E8 !important;
    }
  }
}
.title-page {
  font-weight: 500;
  font-size: 18px;
}
</style>
