import constants from '@/constants'

export class DataUtils {
  static calculatePage(totalRecord) {
    if (totalRecord % constants.itemsPerPage !== 0) {
      return parseInt(totalRecord / constants.itemsPerPage) + 1
    } else {
      return parseInt(totalRecord / constants.itemsPerPage)
    }
  }
  static customerCalculatePage(totalRecord) {
    if (totalRecord % constants.pageSize !== 0) {
      return parseInt(totalRecord / constants.pageSize) + 1
    } else {
      return parseInt(totalRecord / constants.pageSize)
    }
  }
  static calculateEmployeePage(totalRecord) {
    if (totalRecord % constants.numberEmployeesPerPage !== 0) {
      return parseInt(totalRecord / constants.numberEmployeesPerPage) + 1
    } else {
      return parseInt(totalRecord / constants.numberEmployeesPerPage)
    }
  }

  static calculatePageByItemsPerPage(totalRecord, itemsPerPage) {
    if (totalRecord % itemsPerPage !== 0) {
      return parseInt(totalRecord / itemsPerPage) + 1
    } else {
      return parseInt(totalRecord / itemsPerPage)
    }
  }

  static checkRoleAccept(role) {
    const acceptRoles = constants.roleAccept
    return acceptRoles.includes(role)
  }
}
