import apis from './apis'
import { BaseService } from './baseService'

export class UserService extends BaseService {
  static async getInfo() {
    try {
      const response = await this.request({ auth: true }).get(apis.user_info)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async searchCustomer(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.search_customer,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getCustomerInfo(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.customer_info,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async activeCustomer(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.active_customer,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async deactiveCustomer(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.deactive_customer,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getShops(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.get_shops,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getRoles() {
    try {
      const response = await this.request({ auth: true }).get(apis.get_roles)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async searchEmployee(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.search_employee,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async employeeCreate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.employee_create,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async employeeUpdate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.employee_update,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async employeeDelete(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.employee_delete,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async employeeGetInfo(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.employee_get_info,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async updateShopPriceSale(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.update_shop_price_sale,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async forgotPassword(pars) {
    try {
      const response = await this.request().post(apis.forgot_password, pars)
      return response
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async forgotPasswordVerify(pars) {
    try {
      const response = await this.request().post(
        apis.forgot_password_verify_otp,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async forgotPasswordUpdate(pars) {
    try {
      const response = await this.request().post(
        apis.forgot_password_update,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async changePasswordCheck(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.change_password_check,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async changePasswordUpdate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.change_password_update,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async addressGetAll(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.address_get_all,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async addressGetDefault(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `${apis.address_get_default}/${id}`
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async addressCreate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.address_create,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async addressUpdate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.address_update,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getOperatorPostOffice() {
    try {
      const response = await this.request({ auth: true }).get(
        apis.get_operator_postoffice
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async searchEmployeePostoffice(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.search_employee_postoffice,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getShopsOfSale(userId) {
    try {
      const response = await this.request({ auth: true }).get(
        `${apis.get_shops_of_sale}/${userId}`
      );
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async tunrOff(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.tunr_off,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async createShop(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.create_shop,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async createManage(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.create_manage,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async activeManage(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.active_manage,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async inactiveManage(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.inactive_manage,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async searchManage(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.search_manage,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }
}
