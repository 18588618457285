import {
  initCurrentUserStateMiddleware,
  checkAccessMiddleware,
  setPageTitleMiddleware
} from './middlewares'
import routes from './routes'

routes.beforeEach(initCurrentUserStateMiddleware)
routes.beforeEach(checkAccessMiddleware)
routes.beforeEach(setPageTitleMiddleware)

export default routes
