<template>
  <!-- If using vue-router -->
  <router-view />
</template>

<script>
export default {
  name: 'App',
  components: {},
  data: () => ({})
}
</script>
<style src="@/styles/app.scss" lang="scss" />
